import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";
import {
  QueryForWorkbook,
  QueryForWorkbookVariables,
} from "./types/QueryForWorkbook";
import {
  CreateExplainWorkbook,
  CreateExplainWorkbookVariables,
} from "./types/CreateExplainWorkbook";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import Callout from "components/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationCircle,
  faRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";
import SQL from "components/SQL";
import { useRoutes } from "utils/routes";
import classNames from "classnames";
import { CreateSteps, WorkbookCreationHeader } from "../ChooseParameters";
import CopyToClipboard from "components/CopyToClipboard";
import Tip from "components/Tip";
import Button from "components/Button";

export type LocationState = {
  queryText: string;
  name: string;
  description: string;
};
type ParamNamesType = {
  [key: string]: {
    name: string;
    initialName: string;
    errorMessage?: string;
  };
};

const MERGE_MESSAGE = "Name already taken.";

const ReviewQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryId = searchParams.get("queryId");
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const state = location.state as LocationState;
  const [query, setQuery] = useState(state?.queryText ?? "");
  const [name, setName] = useState(
    state?.name ?? (queryId ? `Query #${queryId}` : "New workbook"),
  );
  const [description, setDescription] = useState(state?.description ?? "");
  const [queryEdit, setQueryEdit] = useState(false);
  const [queryNormalized, setQueryNormalized] = useState(false);
  const [paramNames, setParamNames] = useState<ParamNamesType>({});
  const navigate = useNavigate();
  const { databaseWorkbookVariants, databaseWorkbooks, databaseQuery } =
    useRoutes();

  const [createWorkbook] = useMutation<
    CreateExplainWorkbook,
    CreateExplainWorkbookVariables
  >(MUTATION);
  const [getQuery, { loading, error, data }] = useLazyQuery<
    QueryForWorkbook,
    QueryForWorkbookVariables
  >(QUERY);

  const loadQueryInfo = (data: QueryForWorkbook) => {
    if (data.getQueryForWorkbook.validQuery) {
      setErrorMessage("");
      const initialNames = data.getQueryForWorkbook.parameters.reduce(
        (names, val) => {
          names[val.ref.toString()] = { name: val.name, initialName: val.name };
          return names;
        },
        {} as ParamNamesType,
      );
      setParamNames(initialNames);
    } else {
      setErrorMessage(data.getQueryForWorkbook.errorMessage);
    }
  };

  useEffect(() => {
    if (queryId) {
      getQuery({
        variables: { databaseId, queryId },
        onCompleted: (data) => {
          setQuery(data.getQueryForWorkbook.normalizedQuery);
          setQueryNormalized(true);
          loadQueryInfo(data);
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
    }
  }, [databaseId, queryId, getQuery]);

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const handleCheckQuery = () => {
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    getQuery({
      variables: { databaseId, queryText: query },
      onCompleted: (data) => {
        setQueryEdit(false);
        loadQueryInfo(data);
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleCreate = () => {
    if (name === "") {
      setErrorMessage("Name is required");
      return;
    }
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    if (!validQuery) {
      setErrorMessage("Query is not valid");
      return;
    }
    const newAliases = Object.fromEntries(
      Object.entries(paramNames).map(([key, value]) => [key, value.name]),
    );
    createWorkbook({
      variables: {
        databaseId: databaseId,
        name: name,
        queryText: query,
        description: description || null,
        newAliases: newAliases,
      },
      onCompleted: (data) => {
        navigate(
          databaseWorkbookVariants(
            databaseId,
            data.createExplainWorkbook.explainWorkbookId,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  function handleUpdateQuery(newQuery: string) {
    setQueryEdit(true);
    setQuery(newQuery);
    setErrorMessage("");
  }

  function updateErrorMessage(newNames: ParamNamesType) {
    if (Object.values(newNames).some((val) => !!val.errorMessage)) {
      setErrorMessage(
        "Invalid parameter names found. Please check and correct parameter names before proceeding to the next step.",
      );
    } else {
      setErrorMessage("");
    }
  }

  function handleParamNameUpdate(ref: string, newName: string) {
    const paramName = paramNames[ref];
    if (paramName.name !== newName) {
      paramName.name = newName;
      const newNames = { ...paramNames, [ref]: paramName };
      const names = Object.values(newNames).map((val) => val.name);
      const refIdx = names.findIndex((val) => val === newName);
      paramName.errorMessage = validateParamName(names, refIdx);
      setParamNames(newNames);
      updateErrorMessage(newNames);
    }
  }

  function handleMergeName(ref: string) {
    const inComingName = paramNames[ref].name;
    const baseRef = Object.entries(paramNames).find(
      ([key, value]) => value.name === inComingName && key !== ref,
    )[0];
    // Update normalized query's param ref (parameter values from the original query text will be gone here)
    const newQuery = (
      queryNormalized ? query : checkedQuery.normalizedQuery
    ).replace(/(?<=\$)\d+/g, (match) => {
      return match === ref ? baseRef : match;
    });
    setQuery(newQuery);
    setQueryNormalized(true);
    // Make new paramNames without the incoming
    const newNames = { ...paramNames };
    delete newNames[ref];
    setParamNames(newNames);
    updateErrorMessage(newNames);
  }

  const checkedQuery = data?.getQueryForWorkbook;
  const validQuery = checkedQuery?.validQuery && !queryEdit;

  // Not a delete as nothing has been persisted yet at this point
  const handleDeleteWorkbook = () => {
    queryId
      ? navigate(databaseQuery(databaseId, queryId))
      : navigate(databaseWorkbooks(databaseId));
  };
  const workbookTitle = (
    <WorkbookCreationHeader
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      handleDeleteWorkbook={handleDeleteWorkbook}
    />
  );

  const editButton = (
    <button
      onClick={() => {
        setQueryEdit(true);
        setErrorMessage("");
      }}
    >
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );
  const queryTextWithParamNames =
    validQuery &&
    (queryNormalized ? query : checkedQuery.normalizedQuery).replace(
      /(?<=\$)\d+/g,
      (match) => {
        const matchedParam = paramNames[match]?.name;
        return matchedParam ?? match;
      },
    );

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${name}`}
      title={workbookTitle}
      pageCategory="query-tuning"
      pageName="workbooks"
      layout={validQuery ? "sidebar" : "default"}
    >
      <Panel
        title={<CreateSteps step="step1" />}
        secondaryTitle={validQuery && editButton}
        className="mt-1 flex-grow"
      >
        <PanelSection className="flex-grow flex flex-col items-stretch gap-2">
          {validQuery && (
            <Callout>
              In order to process this query, we normalized it and turned
              positional parameters (<code>$1</code>) into named parameters (
              <code>$param</code>). Because we do not know if a parameter
              contains the same content, we suffix the named parameters with
              consecutive numbers—for example, <code>param</code>,{" "}
              <code>param_2</code>, and <code>param_3</code>.
            </Callout>
          )}
          <div className="flex-grow">
            {validQuery ? (
              <QueryTextArea
                className="h-full"
                queryText={queryTextWithParamNames}
              />
            ) : (
              <textarea
                className="h-full resize-none rounded border border-[#E8E8EE] p-2 text-[#606060] w-full font-query text-[13px]"
                placeholder="Paste query text here..."
                value={query}
                onChange={(e) => handleUpdateQuery(e.target.value)}
              ></textarea>
            )}
          </div>
          {errorMessage && (
            <div className="text-[#C22426]">
              <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
            </div>
          )}
          <div>
            {validQuery ? (
              <button
                className="btn btn-success"
                onClick={handleCreate}
                disabled={!!errorMessage}
              >
                Choose Parameters
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={handleCheckQuery}
                disabled={query === ""}
              >
                Verify Query
              </button>
            )}
          </div>
        </PanelSection>
      </Panel>
      <div className="h-10">
        {/* margin for above panel; due to layout we can't specify margin there directly */}
      </div>
      {validQuery && (
        <div className="w-[320px]">
          <Callout className="mb-4" thin>
            {checkedQuery.parameters.length} parameters detected
          </Callout>
          <div className="leading-7 font-medium my-2">
            Rename Parameters{" "}
            <Tip content="We try to infer parameter names from the keys. In some cases, this isn't possible, so we use a generic name instead. You can update the names here." />
          </div>
          <div className="grid gap-2 form-group">
            {Object.keys(paramNames).map((ref) => {
              const paramName = paramNames[ref];
              return (
                <div key={ref}>
                  <div className="flex">
                    <input
                      className="form-control"
                      value={paramName.name}
                      onChange={(e) =>
                        handleParamNameUpdate(ref, e.target.value)
                      }
                    />
                  </div>
                  {paramName.errorMessage && (
                    <div className="flex items-center mt-1">
                      <div className="text-[#C22426]">
                        {paramName.errorMessage}
                      </div>
                      {paramName.errorMessage === MERGE_MESSAGE && (
                        <>
                          <div className="ml-1 flex-grow">
                            <Tip
                              content={
                                <>
                                  Parameter names must be unique. If the
                                  conflicting parameters always refer to the
                                  same value, they can be merged together.
                                  Otherwise they must be renamed.
                                </>
                              }
                            />
                          </div>
                          <Button
                            bare
                            onClick={() => handleMergeName(ref)}
                            className="text-[#337AB7]"
                          >
                            Merge
                          </Button>{" "}
                          <span className="mx-1">or</span>
                          <Button
                            bare
                            onClick={() =>
                              handleParamNameUpdate(ref, paramName.initialName)
                            }
                            className="text-[#337AB7]"
                          >
                            Reset
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <Button
            bare
            className="text-[#337AB7] mb-2"
            onClick={() => {
              const newValues = { ...paramNames };
              Object.values(newValues).forEach((value) => {
                value.name = value.initialName;
                value.errorMessage = null;
              });
              setParamNames(newValues);
              setErrorMessage("");
            }}
          >
            <FontAwesomeIcon icon={faRotateLeft} /> Reset all names
          </Button>
        </div>
      )}
    </PageContent>
  );
};

export const QueryTextArea = ({
  queryText,
  className,
  showCopyToClipboard,
}: {
  queryText: string;
  className?: string;
  showCopyToClipboard?: boolean;
}) => {
  return (
    <div
      className={classNames(
        "relative min-h-[128px] rounded border border-[#E8E8EE] p-2 text-[#606060]",
        className,
      )}
    >
      <div className="absolute top-2 bottom-2 left-2 right-2 overflow-y-auto">
        {showCopyToClipboard && (
          <CopyToClipboard
            className="float-right text-[12px] text-[#606060] ml-1 relative z-10"
            label="copy"
            content={queryText}
          />
        )}
        <SQL sql={queryText} />
      </div>
    </div>
  );
};

export const validateParamName = (names: string[], index: number): string => {
  const name = names[index];
  const otherNames = [...names.slice(0, index), ...names.slice(index + 1)];
  if (!/^[a-z][a-z0-9_]+$/.test(name)) {
    return "Parameter names must only contain lowercase letters, numbers, and underscores.";
  } else if (otherNames.includes(name)) {
    return MERGE_MESSAGE;
  }
  return "";
};

export default ReviewQuery;
