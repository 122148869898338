import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import moment from "moment-timezone";

import {
  formatBytes,
  formatMs,
  formatNumber,
  formatPercent,
  formatTimestampShort,
} from "utils/format";

import Identicon from "components/Identicon";

import styles from "./style.module.scss";

import { useRoutes } from "utils/routes";
import Grid from "components/Grid";
import { mru } from "utils/array";

interface Explain {
  id: string;
  humanId: string;
  fingerprint: string;
  seenAt: number;
  planNodeTypes: string[];
  querySample: {
    runtimeMs: number;
  };
  query: {
    id: string;
  };
  totalCost: number;
  totalBlkReadTime: number;
  totalSharedBlksRead: number;
}

type FlatExplain = Omit<Explain, "querySample"> & {
  runtimeMs: number;
  pctIoTime: number;
  nodeTypesStr: string | undefined;
};

type CompareCandidates = [planA?: string, planB?: string];

type Props = {
  databaseId: string;
  explains: Explain[];
  blockSize: number;
  compareCandidates?: CompareCandidates;
  onCompareCandidatesChanged?: (candidates: CompareCandidates) => void;
};

const ExplainTable: React.FunctionComponent<Props> = ({
  databaseId,
  explains,
  blockSize,
  compareCandidates,
  onCompareCandidatesChanged,
}) => {
  const { databaseQueryExplain } = useRoutes();

  function handleToggleCompareCandidate(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = e.currentTarget;
    const candidateId = e.currentTarget.dataset.compareId;
    const newCandidates = checked
      ? mru(candidateId, compareCandidates, 2)
      : compareCandidates.filter((id) => id != candidateId);

    onCompareCandidatesChanged(newCandidates as CompareCandidates);
  }
  const explainData = useMemo(() => {
    return explains.map<FlatExplain>((explain) => {
      const { querySample, ...rest } = explain;
      const nodeTypesStr = nodeTypesToStr(rest.planNodeTypes);

      return {
        ...rest,
        runtimeMs: querySample && querySample.runtimeMs,
        pctIoTime:
          querySample && explain.totalBlkReadTime != null
            ? explain.totalBlkReadTime / querySample.runtimeMs
            : null,
        nodeTypesStr,
      };
    });
  }, [explains]);
  return (
    <Grid
      data={explainData}
      striped
      defaultSortBy="seenAt"
      noRowsText="No EXPLAIN plans found"
      pageSize={20}
      columns={[
        {
          header: "",
          width: compareCandidates ? "34px" : false,
          disableSort: true,
          field: "humanId",
          renderer: function CompareSelectCell({ fieldData }) {
            const checked = compareCandidates.includes(fieldData);
            return (
              <input
                type="checkbox"
                data-compare-id={fieldData}
                className="cursor-pointer !mt-0.5"
                checked={checked}
                onChange={handleToggleCompareCandidate}
              />
            );
          },
        },
        {
          field: "seenAt",
          width: "minmax(20%,210px)",
          defaultSortOrder: "desc",
          header: "Executed at",
          renderer: function ExplainSeenAtCell({ fieldData, rowData }) {
            const explainTs = formatTimestampShort(moment.unix(fieldData));
            const explainUrl =
              rowData.query &&
              databaseQueryExplain(
                databaseId,
                rowData.query.id,
                rowData.humanId,
              );
            return explainUrl ? (
              <Link to={explainUrl}>{explainTs}</Link>
            ) : (
              explainTs
            );
          },
        },
        {
          field: "fingerprint",
          width: "minmax(10%,100px)",
          header: "Plan",
          renderer: function PlanIdCell({ fieldData }) {
            return (
              <>
                <Identicon identity={fieldData} />
                <span title={fieldData}>{fieldData.substring(0, 7)}</span>
              </>
            );
          },
        },
        {
          field: "totalCost",
          width: "minmax(10%,100px)",
          header: "Est. Cost",
          style: "number",
          nullValue: "-",
          renderer: function TotalCostCell({ fieldData }) {
            return formatNumber(fieldData);
          },
        },
        {
          field: "runtimeMs",
          width: "minmax(10%,100px)",
          header: "Runtime",
          style: "number",
          nullValue: "-",
          renderer: function RuntimeCell({ fieldData }) {
            return formatMs(fieldData);
          },
        },
        {
          field: "totalBlkReadTime",
          width: "minmax(10%,100px)",
          header: "I/O Read Time",
          style: "number",
          nullValue: "-",
          renderer: function IOReadTimeCell({ fieldData }) {
            return formatMs(fieldData);
          },
        },
        {
          field: "pctIoTime",
          width: "64px",
          header: "",
          nullValue: "-",
          renderer: function PctIOTimeCell({ fieldData }) {
            return (
              <span
                className={classNames({
                  [styles.redHighlight]: fieldData > 0.5,
                })}
              >
                {formatPercent(fieldData, 0)}
              </span>
            );
          },
        },
        {
          field: "totalSharedBlksRead",
          width: "minmax(10%,100px)",
          header: "Read From Disk",
          style: "number",
          nullValue: "-",
          renderer: function ReadFromDiskCell({ fieldData }) {
            return formatBytes(fieldData * blockSize);
          },
        },
        {
          field: "nodeTypesStr",
          width: "minmax(20%,1fr)",
          header: "Plan Nodes",
          className: styles.planNodesColumn,
          headerClassName: styles.planNodesColumnHeader,
          nullValue: "-",
        },
      ]}
    />
  );
};

export const nodeTypesToStr = (nodeTypes: string[]): string => {
  let nodeTypesStr: string;
  if (nodeTypes) {
    const nodeCountThreshold = 5;
    const nodeShortlistLen = 3;

    const nodeCount = nodeTypes?.length;
    const elide = nodeCount > nodeCountThreshold;
    const nodes = elide ? nodeTypes.slice(0, nodeShortlistLen) : nodeTypes;
    nodeTypesStr = nodes.join(" · ");
    if (elide) {
      const extraCount = nodeCount && Math.max(0, nodeCount - nodeShortlistLen);
      nodeTypesStr += ` +${extraCount} more`;
    }
  }
  return nodeTypesStr;
};

export default ExplainTable;
