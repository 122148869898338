import React, { useState } from "react";

import queryString from "query-string";
import {
  ExplainCostMetric,
  useCostMetric,
  useSetCostMetric,
  WithExplainCostMetric,
} from "components/WithExplainCostMetric";
import ExplainDiff from "components/ExplainDiff";
import { ComparablePlanType } from "components/WithExplainPlan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import Grid, { MsCell } from "components/Grid";
import ModalContainer from "components/ModalContainer";
import { mru } from "utils/array";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Button from "components/Button";
import { Link, useLocation } from "react-router-dom";
import ExplainFingerprint from "components/ExplainFingerprint";
import { formatMs } from "utils/format";
import classNames from "classnames";
import Tip from "components/Tip";

export const ExplainComparison = ({
  comparablePlans,
  linkToComparison,
}: {
  comparablePlans: ComparablePlanType[];
  linkToComparison: (planA: string, planB?: string) => string;
}) => {
  const [planA, planB] = useComparisonPlans(comparablePlans);
  // If we don't have both plans selected, open the modal immediately
  const [open, setOpen] = useState(!planA || !planB);

  function toggleOpen() {
    setOpen((open) => !open);
  }
  const diffPlanA = planA && { label: planA.label, plan: planA.plan.plan };
  const diffPlanB = planB && { label: planB.label, plan: planB.plan.plan };

  return (
    <div>
      <WithExplainCostMetric>
        <Panel
          className={
            "mt-[1px]" /* TODO: On Firefox, the top border of the panel is cut off without an extra margin
        here because we're using box-shadow to draw the border; this should not be necessary. */
          }
          title="Plan Comparison"
          secondaryTitle={
            <Button
              className="text-[#337ab7] hover:text-[#23527c]"
              bare
              onClick={toggleOpen}
            >
              Select plans
            </Button>
          }
        >
          <PanelSection>
            {(!planA || !planB) && (
              <div>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="text-[#31708f] mr-1"
                />
                <Button
                  className="text-[#337ab7] hover:text-[#23527c]"
                  bare
                  onClick={toggleOpen}
                >
                  Select plans
                </Button>{" "}
                to continue
              </div>
            )}
            {open && (
              <SelectPlansToCompareModal
                toggleOpen={toggleOpen}
                comparablePlans={comparablePlans}
                linkToComparison={linkToComparison}
              />
            )}
            {planA && planB && (
              <>
                <ExplainSelectCostMetric />
                <ExplainDiff planA={diffPlanA} planB={diffPlanB} />
              </>
            )}
          </PanelSection>
        </Panel>
      </WithExplainCostMetric>
    </div>
  );
};

export function useComparisonPlans(
  comparablePlans: ComparablePlanType[] | undefined,
) {
  const { search } = useLocation();
  if (!comparablePlans) {
    return undefined;
  }
  const searchParams = queryString.parse(search);

  const { planA: planAId, planB: planBId } = searchParams;

  const planA = comparablePlans.find((p) => p.id === planAId);
  const planB = comparablePlans.find((p) => p.id === planBId);

  return [planA, planB];
}

function SelectPlansToCompareModal({
  comparablePlans,
  toggleOpen,
  linkToComparison,
}: {
  comparablePlans: ComparablePlanType[];
  toggleOpen: () => void;
  linkToComparison: (planA: string, planB?: string) => string;
}) {
  const [planA, planB] = useComparisonPlans(comparablePlans);

  const initialCandidates = [] as string[];
  if (planA) {
    initialCandidates.push(planA.id);
  }
  if (planB) {
    initialCandidates.push(planB.id);
  }
  const [compareCandidates, setCompareCandidates] =
    useState<string[]>(initialCandidates);

  function addCompareCanditate(candidateId: string) {
    const newCandidates = mru(candidateId, compareCandidates, 2);
    setCompareCandidates(newCandidates);
  }

  function clearCompareCanditate(candidateId: string) {
    const newCandidates = compareCandidates.filter((id) => id !== candidateId);
    setCompareCandidates(newCandidates);
  }

  let candidateA: ComparablePlanType, candidateB: ComparablePlanType;
  if (compareCandidates.length > 0) {
    candidateA = comparablePlans.find((p) => p.id === compareCandidates[0]);
  }
  if (compareCandidates.length > 1) {
    // If the user selects two plans, we want the oldest selection to be Plan A
    // and the most recent to be Plan B (we expect this is more intuitive).
    // Since the result of `mru` is reversed (it's sorted by recency), we need
    // to switch these:
    candidateB = candidateA;
    candidateA = comparablePlans.find((p) => p.id === compareCandidates[1]);
  }

  const comparisonLink =
    compareCandidates.length < 2
      ? ""
      : linkToComparison(candidateA.id, candidateB.id);

  return (
    <ModalContainer
      title="Select plans to compare"
      layout="centered"
      onClose={toggleOpen}
    >
      <Grid
        pageSize={10}
        data={comparablePlans}
        columns={[
          {
            field: "id",
            header: "",
            disableSort: true,
            width: "34px",
            renderer: ({ fieldData }) => {
              function handleToggleChecked(
                e: React.ChangeEvent<HTMLInputElement>,
              ) {
                if (e.currentTarget.checked) {
                  addCompareCanditate(fieldData);
                } else {
                  clearCompareCanditate(fieldData);
                }
              }
              const checked = compareCandidates.includes(fieldData);
              return (
                <input
                  type="checkbox"
                  className="cursor-pointer !mt-0.5"
                  checked={checked}
                  onChange={handleToggleChecked}
                />
              );
            },
          },
          {
            field: "fingerprint",
            width: "120px",
            renderer: ({ fieldData }) => {
              return <ExplainFingerprint fingerprint={fieldData} />;
            },
          },
          {
            width: "1fr",
            field: "label",
          },
          {
            field: "runtime",
            style: "number",
            nullValue: "n/a",
            renderer: MsCell,
            width: "minmax(10%,180px)",
          },
          {
            field: "ioMs",
            header: "I/O Read Time",
            style: "number",
            nullValue: "n/a",
            renderer: MsCell,
            width: "minmax(10%,180px)",
          },
        ]}
      />
      <div className="mt-4">
        <PlanSummaryTable planA={candidateA} planB={candidateB} />
      </div>
      <Link
        className={classNames(
          "mt-4 btn btn-success",
          !comparisonLink && "disabled pointer-events-none",
        )}
        onClick={comparisonLink ? toggleOpen : undefined}
        to={comparisonLink}
      >
        Compare Plans
      </Link>
    </ModalContainer>
  );
}

function PlanSummaryTable({
  planA,
  planB,
}: {
  planA: ComparablePlanType;
  planB: ComparablePlanType;
}) {
  return (
    <table className="w-full border-spacing-1 border-separate table-fixed">
      <thead>
        <tr>
          <th className="w-16"></th>
          <th className="w-32">Fingerprint</th>
          <th className="w-full">Description</th>
          <th className="w-32 text-right">Runtime</th>
          <th className="w-32 text-right">I/O Read Time</th>
        </tr>
      </thead>
      <tbody>
        <PlanSummaryRow label="Plan A" plan={planA} />
        <PlanSummaryRow label="Plan B" plan={planB} />
      </tbody>
    </table>
  );
}

function PlanSummaryRow({
  label,
  plan,
}: {
  label: string;
  plan: ComparablePlanType;
}) {
  return (
    <tr>
      <th className="whitespace-nowrap h-6" scope="row">
        {label}
      </th>
      <td>{plan ? <ExplainFingerprint explain={plan} /> : "n/a"}</td>
      <td>{plan?.label ?? "n/a"}</td>
      <td className="text-right">
        {plan?.runtime != null ? formatMs(plan.runtime) : "n/a"}
      </td>
      <td className="text-right">
        {plan?.ioMs != null ? formatMs(plan.ioMs) : "n/a"}
      </td>
    </tr>
  );
}

function ExplainSelectCostMetric() {
  const costMetric = useCostMetric();
  const setCostMetric = useSetCostMetric();
  function handleMetricChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setCostMetric(evt.currentTarget.value as ExplainCostMetric);
  }

  return (
    <div className="mb-2 flex gap-2 items-center max-w-full">
      <strong className="text-sm">Compare:</strong>
      <label className="font-semibold flex items-center gap-1 !mb-0">
        <input
          className="!m-0"
          type="radio"
          value="Est. Cost"
          checked={costMetric == "Est. Cost"}
          onChange={handleMetricChange}
        />
        Est. Total Cost (Self)
      </label>
      <label className="font-semibold flex items-center gap-1 !mb-0">
        <input
          className="!m-0"
          type="radio"
          value="Runtime"
          checked={costMetric == "Runtime"}
          onChange={handleMetricChange}
        />
        Runtime (Self)
      </label>
      <label className="font-semibold flex items-center gap-1 !mb-0">
        <input
          className="!m-0"
          type="radio"
          value="I/O Time"
          checked={costMetric == "I/O Time"}
          onChange={handleMetricChange}
        />
        I/O Read Time (Self)
      </label>
      <label className="font-semibold flex items-center gap-1 !mb-0">
        <input
          className="!m-0"
          type="radio"
          value="Rows"
          checked={costMetric == "Rows"}
          onChange={handleMetricChange}
        />
        Rows
      </label>
      <label className="font-semibold flex items-center gap-1 !mb-0">
        <input
          className="!m-0"
          type="radio"
          value="Buffers"
          checked={costMetric == "Buffers"}
          onChange={handleMetricChange}
        />
        Buffers (Self)
        <Tip
          content={
            <>
              Buffers (blocks of data read during query execution) are counted
              per-access, not uniquely, so they will result in over-counting for
              plan nodes that repeatedly access the same data (especially nested
              loop joins). It can still be a useful comparison metric, but
              interpreting it requires caution. Buffer counts are a sum of
              shared and local blocks both hit and read from disk (or the OS
              page cache).
            </>
          }
        />
      </label>
    </div>
  );
}

export default ExplainComparison;
