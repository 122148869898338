import { useMutation, useQuery } from "@apollo/client";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import QUERY from "./Query.graphql";
import QUERY_COMPARISON from "./Query.comparison.graphql";
import {
  ExplainWorkbookDetails,
  ExplainWorkbookDetailsVariables,
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_aliasParamMapList as AliasParamMapType,
} from "./types/ExplainWorkbookDetails";
import {
  ExplainComparisonWorkbook as ExplainComparisonWorkbookType,
  ExplainComparisonWorkbookVariables,
  ExplainComparisonWorkbook_getExplainWorkbookDetails as ExplainComparisonWorkbookDetailsType,
} from "./types/ExplainComparisonWorkbook";

import Loading from "components/Loading";
import { formatMs } from "utils/format";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import { useRoutes } from "utils/routes";
import Grid, { GridColumn, NumberCell } from "components/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faCircleX,
  faExclamationTriangle,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { ExplainWorkbookSidebar } from "../ExplainVariantSidebar";
import { QueryParameterValue, stringifyValue } from "./util";
import Identicon from "components/Identicon";
import { ExplainQueryPanel } from "../ExplainVariant";
import Select from "components/Select";
import Popover from "components/Popover";

import DELETE_MUTATION from "../ChooseParameters/Mutation.delete.graphql";
import {
  DeleteExplainParameterSets,
  DeleteExplainParameterSetsVariables,
} from "../ChooseParameters/types/DeleteExplainParameterSets";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import ChooseParameters from "../ChooseParameters";
import ExplainCompareSidebar from "components/ExplainCompareSidebar";
import WithExplainPlan, {
  ComparablePlanType,
} from "components/WithExplainPlan";
import WithNodeSelection from "components/Explain/WithNodeSelection";
import {
  ExplainComparison,
  useComparisonPlans,
} from "components/ExplainPanel/ExplainComparison";
import { mru } from "utils/array";
import ExplainCompareLinkBar from "../ExplainCompareLinkBar";

type ExplainWorkbookTabType = "compare" | "params";

const ExplainWorkbook = ({ tab }: { tab?: ExplainWorkbookTabType }) => {
  const { workbookId, databaseId } = useParams();
  const { databaseWorkbookVariantResult } = useRoutes();

  const {
    loading: detailsLoading,
    error: detailsError,
    data: detailsData,
  } = useQuery<ExplainWorkbookDetails, ExplainWorkbookDetailsVariables>(QUERY, {
    variables: { workbookId, databaseId },
  });
  const {
    loading: comparisonLoading,
    error: comparisonError,
    data: comparisonData,
  } = useQuery<
    ExplainComparisonWorkbookType,
    ExplainComparisonWorkbookVariables
  >(QUERY_COMPARISON, {
    variables: {
      workbookId,
    },
    skip: tab != "compare",
  });

  const loading = detailsLoading || comparisonLoading;
  const error = detailsError ?? comparisonError;

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const workbook = detailsData.getExplainWorkbookDetails;
  // During the initial workbook creation flow:
  // If the selection is not done yet, show the selection page
  if (!workbook.parameterSetsSelected) {
    return <ChooseParameters workbook={workbook} />;
  }

  const { blockSize } = workbook.server;

  const comparablePlans: ComparablePlanType[] = [];
  if (tab === "compare") {
    [comparisonData.getExplainWorkbookDetails.baselineQuery]
      .concat(comparisonData.getExplainWorkbookDetails.explainQueries)
      .forEach((explainQuery) =>
        explainQuery.explainResults.forEach((e) => {
          const label = `${explainQuery.name} - ${getParameterSetName(
            e.parameterSetId,
            comparisonData.getExplainWorkbookDetails,
          )}`;
          // Do not add results without fingerprint to the comparablePlans
          // as these can be currently-running, errored, or skipped ones (running EXPLAINs using collector)
          e.planFingerprint &&
            comparablePlans.push({
              id: e.id,
              label,
              url: databaseWorkbookVariantResult(
                workbook.databaseId,
                workbook.id,
                explainQuery.id,
                e.id,
              ),
              seenAt: e.createdAt,
              fingerprint: e.planFingerprint,
              runtime: e.runtimeMs,
              ioMs: e.totalBlkReadTime,
              ioBytes: e.totalSharedBlksRead * blockSize,
              totCost: e.totalCost,
              plan: JSON.parse(e.annotatedJson),
            });
        }),
      );
  }

  return (
    <ExplainWorkbookContent
      workbook={workbook}
      blockSize={blockSize}
      comparablePlans={comparablePlans}
      tab={tab}
      permittedToTuneQueries={
        detailsData.getDatabaseDetails.permittedToTuneQueries
      }
    />
  );
};

const ExplainWorkbookContent = ({
  workbook,
  blockSize,
  comparablePlans,
  tab,
  permittedToTuneQueries,
}: {
  workbook: ExplainWorkbookType;
  blockSize: number;
  comparablePlans: ComparablePlanType[];
  tab: ExplainWorkbookTabType;
  permittedToTuneQueries: boolean;
}) => {
  const { databaseWorkbookCompare } = useRoutes();
  const { databaseId, workbookId } = useParams();
  const [plan, comparePlan] = useComparisonPlans(comparablePlans);

  const layout =
    tab === "compare" ? "sidebar" : !tab ? "leftSidebar" : "default";

  function linkToComparison(planA: string, planB?: string) {
    return databaseWorkbookCompare(databaseId, workbookId, planA, planB);
  }

  return (
    <WithExplainPlan plan={plan} comparePlan={comparePlan}>
      <WithNodeSelection>
        <PageContent
          windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
          title={workbook.name}
          pageCategory="explains"
          pageName="workbooks"
          layout={layout}
          featureNav={
            <ExplainWorkbookFeatureNav
              workbookId={workbookId}
              databaseId={databaseId}
            />
          }
        >
          {tab == null && (
            <ExplainOverviewPanel
              key="overview"
              databaseId={databaseId}
              workbook={workbook}
            />
          )}
          {tab == null && (
            <ExplainWorkbookSidebar
              key="overview-sidebar"
              workbook={workbook}
              permittedToTuneQueries={permittedToTuneQueries}
            />
          )}
          {tab === "compare" && (
            <ExplainComparisonLegacyRouteRedirect
              databaseId={databaseId}
              workbookId={workbookId}
            >
              <ExplainComparison
                key="plan-comparison"
                comparablePlans={comparablePlans}
                linkToComparison={linkToComparison}
              />
            </ExplainComparisonLegacyRouteRedirect>
          )}
          {tab === "compare" && (
            <div key="plan-comparison-sidebar" className="w-[400px]">
              <ExplainCompareSidebar
                databaseId={databaseId}
                blockSize={blockSize}
              />
            </div>
          )}
          {tab && tab !== "compare" && (
            <ParameterSetsPanel databaseId={databaseId} workbook={workbook} />
          )}
        </PageContent>
      </WithNodeSelection>
    </WithExplainPlan>
  );
};

const ExplainComparisonLegacyRouteRedirect = ({
  children,
  workbookId,
  databaseId,
}: {
  children: React.ReactNode;
  workbookId: string;
  databaseId: string;
}) => {
  const { databaseWorkbookCompare } = useRoutes();
  const { search } = useLocation();

  const { planCompare } = queryString.parse(search);
  if (!planCompare) {
    return children;
  }
  if (typeof planCompare !== "string") {
    // We never have more than one planCompare value in the URL: if we find more
    // than one, assume it's not valid and ignore it.
    return children;
  }

  const [planA, _vs, planB] = planCompare.split("-");

  if (!planA) {
    return children;
  }

  return (
    <Navigate
      to={databaseWorkbookCompare(databaseId, workbookId, planA, planB)}
    />
  );
};

function getParameterSetName(
  parameterSetId: string,
  workbookDetails: ExplainComparisonWorkbookDetailsType,
): string {
  return (
    workbookDetails.parameterSets.find((set) => set.id === parameterSetId)
      ?.name ?? parameterSetId
  );
}

const ExplainOverviewPanel = ({
  databaseId,
  workbook,
}: {
  databaseId: string;
  workbook: ExplainWorkbookType;
}) => {
  const { databaseWorkbookVariant, databaseWorkbookVariantResult } =
    useRoutes();
  const [compareCandidates, setCompareCandidates] = useState<string[]>([]);
  function handleToggleCompareCandidate(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = e.currentTarget;
    const candidateId = e.currentTarget.dataset.compareId;
    const newCandidates = checked
      ? mru(candidateId, compareCandidates, 2)
      : compareCandidates.filter((id) => id != candidateId);

    setCompareCandidates(newCandidates);
  }
  const [selectedParameterSet, setSelectedParameterSet] =
    useState<AliasParamMapType>(null);
  const handleParameterSetSelected = (selected: AliasParamMapType | null) => {
    setSelectedParameterSet(selected);
  };

  const secondaryTitle = (
    <Select
      placeholder="Filter by Parameter Set..."
      items={workbook.aliasParamMapList}
      itemToString={(item) => item.name}
      value={selectedParameterSet}
      onChange={handleParameterSetSelected}
    />
  );

  let fastestRuntime = Infinity;
  const data: Record<string, any>[] = [];

  [
    workbook.baselineQuery as ExplainQueryType,
    ...workbook.explainQueries,
  ].forEach((exp) => {
    exp.explainResults.forEach((result) => {
      data.push({
        variantId: exp.id,
        variantName: exp.name,
        resultId: result.id,
        planFingerprint: result.planFingerprint,
        parameterSetName: workbook.parameterSets.find(
          (set) => set.id === result.parameterSetId,
        )?.name,
        parameterSetId: result.parameterSetId,
        totalCost: result.totalCost,
        runtimeMs: result.runtimeMs,
        totalBlkReadTime: result.totalBlkReadTime,
        errorMessage: result.errorMessage,
        resultSource: result.resultSource,
        resultStatus: result.status,
      });
    });
  });
  const filteredData = data
    .filter((dat) => {
      const filtered = selectedParameterSet
        ? dat.parameterSetId === selectedParameterSet.id
        : true;
      if (filtered) {
        fastestRuntime = Math.min(fastestRuntime, dat.runtimeMs ?? Infinity);
      }
      return filtered;
    })
    .sort((a, b) => {
      // sort data by variant ID first, set ID second
      const variantComparison = a.variantId - b.variantId;
      if (variantComparison !== 0) {
        return variantComparison;
      }
      // if variant IDs are equal, compare by set ID
      return a.parameterSetId - b.parameterSetId;
    });

  return (
    <>
      <div className="rounded-md bg-[#f7fafc] border border-[#E8E8EE] p-4 mb-4 grid gap-2 text-[#606060]">
        <div className="text-[18px] leading-6">All Query Plans</div>
        <div>
          Choose a query variant to see its query text and settings, or create a
          new variant. Variants can be used to test Postgres planner behavior or
          rewrite queries to improve performance.{" "}
          <a
            href="https://pganalyze.com/docs/query-tuning/query-optimization-examples"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
      <Panel title="Query Plans" secondaryTitle={secondaryTitle}>
        <Grid
          className="grid-cols-[34px_16%_1fr_repeat(3,18%)]"
          data={filteredData}
          columns={[
            {
              header: "",
              disableSort: true,
              field: "resultId",
              renderer: function CompareSelectCell({ fieldData, rowData }) {
                const checked = compareCandidates.includes(fieldData);
                return (
                  <input
                    type="checkbox"
                    data-compare-id={fieldData}
                    className="cursor-pointer !mt-0.5"
                    checked={checked}
                    onChange={handleToggleCompareCandidate}
                    disabled={!rowData.planFingerprint}
                  />
                );
              },
            },
            {
              field: "planFingerprint",
              header: "Plan",
              renderer: function PlanCell({ rowData, fieldData }) {
                return (
                  <Link
                    to={databaseWorkbookVariantResult(
                      databaseId,
                      workbook.id,
                      rowData.variantId,
                      rowData.resultId,
                    )}
                  >
                    <Identicon identity={fieldData} />
                    <span title={fieldData}>{fieldData.substring(0, 7)}</span>
                  </Link>
                );
              },
              nullValue: "No run",
            },
            {
              field: "variantName",
              header: "Variant",
              renderer: function VariantCell({ rowData, fieldData }) {
                return (
                  <Link
                    to={databaseWorkbookVariant(
                      databaseId,
                      workbook.id,
                      rowData.variantId,
                    )}
                  >
                    {fieldData}
                  </Link>
                );
              },
            },
            {
              field: "parameterSetName",
              header: "Parameter Set",
            },
            {
              field: "totalCost",
              header: "Est. Cost",
              renderer: NumberCell,
              style: "number",
              nullValue: "-",
            },
            {
              field: "runtimeMs",
              header: "Runtime",
              renderer: ({ fieldData, rowData }) => {
                return (
                  <RuntimeMsCell
                    fieldData={fieldData}
                    rowData={rowData}
                    fastestRuntime={fastestRuntime}
                  />
                );
              },
              style: "number",
            },
          ]}
        />
      </Panel>
      <ExplainCompareLinkBar
        databaseId={databaseId}
        workbookId={workbook.id}
        compareCandidates={compareCandidates}
      />
    </>
  );
};

export const IOReadPopover = ({
  totalBlkReadTime,
}: {
  totalBlkReadTime: number;
}) => {
  return (
    <Popover
      title={
        <>
          I/O Read Time:{" "}
          <span className="font-mono">{formatMs(totalBlkReadTime)}</span>
        </>
      }
      content={
        <>
          This query experienced I/O read time, indicating that the cache might
          not have been warmed up during execution. As a best practice, it's
          recommended to warm up the cache by running the query a few times
          before using EXPLAIN.
        </>
      }
      popupClassName="font-sans"
    >
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-[2px]" />
    </Popover>
  );
};

export const RuntimeMsCell = ({
  fieldData,
  rowData,
  fastestRuntime,
}: {
  fieldData: number;
  rowData: Record<string, any>;
  fastestRuntime: number;
}) => {
  const fastest = fieldData === fastestRuntime;
  if (fieldData === null) {
    // no runtime data (error, without analyze - with overview, parameter sets without result is not listed)

    if (rowData.errorMessage) {
      return (
        <Popover content={rowData.errorMessage}>
          <div className="text-[#D1242F]">
            <FontAwesomeIcon icon={faCircleX} className="mr-[2px]" />
            Error
          </div>
        </Popover>
      );
    }
    if (rowData.resultSource === "collector_upload") {
      if (rowData.resultStatus === "synced") {
        // collector + without analyze
        return (
          <Popover content="EXPLAIN ran without ANALYZE due to timeout (one minute).">
            <div>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="text-[#CA8A04] mr-[2px]"
              />
              Timeout
            </div>
          </Popover>
        );
      } else {
        // collector is still running EXPLAIN
        return "-";
      }
    }
    // manual + without analyze
    return (
      <Popover content="EXPLAIN uploaded without ANALYZE.">
        <div>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-[#CA8A04] mr-[2px]"
          />
          -
        </div>
      </Popover>
    );
  }
  return (
    <span className={fastest ? "text-[#2B5827]" : ""}>
      {rowData.totalBlkReadTime > 0 && (
        <IOReadPopover totalBlkReadTime={rowData.totalBlkReadTime} />
      )}
      {fastest && (
        <FontAwesomeIcon icon={faBolt} title="Fastest" className="mr-[2px]" />
      )}
      {formatMs(fieldData)}
    </span>
  );
};

export const ExplainWorkbookFeatureNav = ({
  workbookId,
  databaseId,
}: {
  workbookId: string;
  databaseId: string;
}) => {
  const {
    databaseWorkbookVariants,
    databaseWorkbookCompare,
    databaseWorkbookParams,
  } = useRoutes();

  return (
    <PageSecondaryNavigation>
      <PageNavLink
        to={databaseWorkbookVariants(databaseId, workbookId)}
        end={false}
      >
        Overview
      </PageNavLink>
      <PageNavLink to={databaseWorkbookCompare(databaseId, workbookId)}>
        Compare Plans
      </PageNavLink>
      <PageNavLink to={databaseWorkbookParams(databaseId, workbookId)}>
        Parameter Sets
      </PageNavLink>
    </PageSecondaryNavigation>
  );
};

const ParameterSetsPanel = ({
  databaseId,
  workbook,
}: {
  workbook: ExplainWorkbookType;
  databaseId: string;
}) => {
  const [showOnlyDiff, setShowOnlyDiff] = useState(false);
  const [deleteExplainParameterSets] = useMutation<
    DeleteExplainParameterSets,
    DeleteExplainParameterSetsVariables
  >(DELETE_MUTATION);

  const secondaryTitle = (
    <div className="flex">
      <label>
        <input
          type="checkbox"
          checked={showOnlyDiff}
          id="show_only_diff"
          onChange={(evt) => setShowOnlyDiff(evt.target.checked)}
        />{" "}
        Show only parameters with different values
      </label>
    </div>
  );

  const paramSetsCount = workbook.parameterSets.length;
  const aliasList = workbook.parameterRefAliases;
  const data = aliasList.map((rawAlias) => {
    const alias = `$${rawAlias}`;
    const dat: Record<string, { value: QueryParameterValue; type?: string }> = {
      alias: { value: alias },
    };
    workbook.aliasParamMapList.forEach((val) => {
      const colName = `set${val.id}`;
      // val.parameters: { "$alias1": { value: "foo", type: "text" }, ... }
      dat[colName] = val.parameters[alias];
    });
    return dat;
  });
  const columns: GridColumn<
    (typeof data)[number],
    keyof (typeof data)[number]
  >[] = [
    {
      field: "alias",
      header: "",
      renderer: ({ fieldData }) => fieldData.value,
      className: "font-medium",
      width: "1fr",
      disableSort: true,
    },
  ];
  let valueWidth = "15%";
  switch (paramSetsCount) {
    case 1:
      valueWidth = "50%";
      break;
    case 2:
      valueWidth = "40%";
      break;
    case 3:
      valueWidth = "25%";
      break;
  }

  workbook.aliasParamMapList.forEach((aliasParamMap) => {
    columns.push({
      field: `set${aliasParamMap.id}`,
      header: (
        <div>
          {aliasParamMap.name}
          {workbook.aliasParamMapList.length > 1 && (
            <FontAwesomeIcon
              icon={faTrashAlt}
              title="Delete"
              className="text-[#CA1515] ml-2 cursor-pointer"
              onClick={() => {
                if (
                  window.confirm(
                    `Delete parameter set "${aliasParamMap.name}"? This will delete all EXPLAIN results for this parameter set.`,
                  )
                ) {
                  deleteExplainParameterSets({
                    variables: {
                      workbookId: workbook.id,
                      parameterSetId: aliasParamMap.id,
                    },
                    refetchQueries: [
                      {
                        query: WORKBOOK_DETAIL_QUERY,
                        variables: {
                          workbookId: workbook.id,
                          databaseId,
                        },
                      },
                    ],
                    awaitRefetchQueries: true,
                  });
                }
              }}
            />
          )}
        </div>
      ),
      renderer: function ParamValueCell({ fieldData }) {
        return (
          <span title={stringifyValue(fieldData.value, fieldData.type)}>
            {stringifyValue(fieldData.value, fieldData.type)}
          </span>
        );
      },
      width: valueWidth,
      disableSort: true,
    });
  });

  const filteredData = data.filter((dat) => {
    if (showOnlyDiff) {
      const colNames = workbook.parameterSets.map((val) => `set${val.id}`);
      const values = colNames.map((colName) => dat[colName]);
      return values.some((value) => value.value !== values[0].value);
    }
    return true;
  });

  return (
    <>
      <ExplainQueryPanel
        workbook={workbook}
        explainQuery={workbook.baselineQuery}
      />
      <Panel title="Parameter Sets" secondaryTitle={secondaryTitle}>
        <Grid
          className="grid-cols-[130px_1fr]"
          data={filteredData}
          columns={columns}
        />
      </Panel>
    </>
  );
};

export default ExplainWorkbook;
