import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import { QuerySamplesForWorkbook_getQuerySamples as QuerySample } from "./types/QuerySamplesForWorkbook";

export function cleanupSamples(
  samples: QuerySample[],
  workbook: ExplainWorkbookType,
): QuerySample[] {
  const paramCount = workbook.parameterRefAliases.length;
  // Track params we've seen before to de-duplicate parameter set samples
  const existingParameters: Set<string> = new Set();
  return samples.filter((sample) => {
    if (sample.jsonParameters == null) {
      // jsonParameters becomes null when params weren't able to extracted (e.g. redacted)
      return false;
    } else if (Object.keys(sample.jsonParameters).length !== paramCount) {
      // Skip samples having different number of parameters than the baseline (workbook) one for now
      // This happens when the normalized query is like `WHERE id IN ($1)` (or `WHERE id IN ($id)`),
      // but the sample has multiple ids for that IN operator
      return false;
    }

    if (sample.explain) {
      // With explain, do not de-duplicate (as it's already selected well per plan at the server level)
      return true;
    }
    const stringified = JSON.stringify(sample.jsonParameters);
    const existing = existingParameters.has(stringified);
    if (!existing) {
      existingParameters.add(stringified);
    }
    return !existing;
  });
}
