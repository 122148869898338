import React, { useState } from "react";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import QUERY from "./Query.graphql";
import CREATE_MUTATION from "./Mutation.create.graphql";
import DELETE_MUTATION from "./Mutation.delete.graphql";
import DELETE_WORKBOOK_MUTATION from "../ExplainVariantSidebar/Mutation.workbook.graphql";
import DELETE_RESULTS_MUTATION from "./Mutation.deleteResults.graphql";

import WORKBOOK_LIST_QUERY from "../Query.graphql";
import {
  QuerySamplesForWorkbook,
  QuerySamplesForWorkbookVariables,
  QuerySamplesForWorkbook_getQuerySamples as QuerySample,
} from "./types/QuerySamplesForWorkbook";
import { formatTimestampShort } from "utils/format";
import moment from "moment";
import Grid, { MsCell } from "components/Grid";
import SQL from "components/SQL";
import Identicon from "components/Identicon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/pro-regular-svg-icons";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";
import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import Loading from "components/Loading";
import {
  CreateExplainParameterSets,
  CreateExplainParameterSetsVariables,
} from "./types/CreateExplainParameterSets";
import {
  DeleteExplainParameterSets,
  DeleteExplainParameterSetsVariables,
} from "./types/DeleteExplainParameterSets";
import {
  aliasParamMapToString,
  jsonParametersToString,
} from "../ExplainWorkbook/util";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import CreateParameterSetPanel from "../CreateParameterSetPanel";
import EditParameterSetPanel from "../EditParameterSetPanel";
import {
  DeleteExplainWorkbook,
  DeleteExplainWorkbookVariables,
} from "../ExplainVariantSidebar/types/DeleteExplainWorkbook";
import {
  DeleteExplainResults,
  DeleteExplainResultsVariables,
} from "./types/DeleteExplainResults";
import Button from "components/Button";
import { QueryTextArea } from "../ReviewQuery";
import EditParameterSettingsPanel from "../EditParameterSettingsPanel";
import {
  faCircle1,
  faCircle2,
  faCircle3,
  faCog,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import PillButtonBar from "components/PillButtonBar";
import Callout from "components/Callout";
import WorkbookNameEditPanel from "../WorkbookNameEditPanel";
import classNames from "classnames";
import { cleanupSamples } from "./util";

type AddParamMethodType = "sample" | "query" | "manual";
type SelectedParameter = {
  fingerprint: string | null;
  runtimeMs: number | null;
  paramInSql: string;
  onEdit: () => void | null;
  onDelete: () => void;
  autoCreatedFromQuery: boolean;
};

const ChooseParameters = ({ workbook }: { workbook: ExplainWorkbookType }) => {
  const { databaseId } = useParams();

  const { loading, error, data } = useQuery<
    QuerySamplesForWorkbook,
    QuerySamplesForWorkbookVariables
  >(QUERY, {
    variables: {
      databaseId,
      workbookId: workbook.id,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const querySamples = cleanupSamples(data.getQuerySamples, workbook);
  return (
    <ChooseParametersContent workbook={workbook} querySamples={querySamples} />
  );
};

const ChooseParametersContent = ({
  workbook,
  querySamples,
}: {
  workbook: ExplainWorkbookType;
  querySamples: QuerySample[];
}) => {
  const { databaseId } = useParams();
  const [sampleIds, setSampleIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageForSamples, setErrorMessageForSamples] = useState("");
  const [addParamMethod, setAddParamMethod] = useState<AddParamMethodType>(
    querySamples.length ? "sample" : "query",
  );
  const [parameterSetIdForEdit, setParameterSetIdForEdit] = useState(null);
  const [showParameterSettingsEditPanel, setShowParameterSettingsEditPanel] =
    useState(false);
  const { databaseQueryExplain, databaseWorkbookVariantRun } = useRoutes();
  const navigate = useNavigate();
  const baselineQuery = workbook.baselineQuery;

  const [createExplainParameterSets] = useMutation<
    CreateExplainParameterSets,
    CreateExplainParameterSetsVariables
  >(CREATE_MUTATION);
  const [deleteExplainParameterSets] = useMutation<
    DeleteExplainParameterSets,
    DeleteExplainParameterSetsVariables
  >(DELETE_MUTATION);

  const filteredData = querySamples.filter((sample) =>
    jsonParametersToString(sample.jsonParameters)
      .toLowerCase()
      .includes(searchTerm.trim().toLowerCase()),
  );

  function handleEditDismiss() {
    setParameterSetIdForEdit(null);
  }

  const handleRunExplain = () => {
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        querySampleIds: sampleIds,
        finalize: true,
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: { workbookId: workbook.id, databaseId },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(
          databaseWorkbookVariantRun(
            workbook.databaseId,
            workbook.id,
            baselineQuery.id,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const queryWithNoParams =
    Object.keys(baselineQuery.paramRefAliasMap).length === 0;
  const queryWithSamples = querySamples.length > 0;

  const workbookTitle = (
    <WorkbookCreationHeaderWithWorkbook workbook={workbook} />
  );
  const editParameterSettings = (
    <Button
      bare
      onClick={() => setShowParameterSettingsEditPanel(true)}
      className="text-[#337AB7]"
    >
      <FontAwesomeIcon icon={faCog} className="mr-0.5" /> Edit parameter
      settings
    </Button>
  );
  const selectedParameters: SelectedParameter[] = [];
  sampleIds.forEach((sId) => {
    const sample = querySamples.find((s) => s.id === sId);
    selectedParameters.push({
      fingerprint: sample.explain?.fingerprint,
      runtimeMs: sample.runtimeMs,
      paramInSql: jsonParametersToString(sample.jsonParameters),
      onEdit: null,
      onDelete: () => {
        setSampleIds(sampleIds.filter((id) => id !== sId));
      },
      autoCreatedFromQuery: false,
    });
  });
  workbook.aliasParamMapList.forEach((paramMap) => {
    selectedParameters.push({
      fingerprint: null,
      runtimeMs: null,
      paramInSql: aliasParamMapToString(paramMap.parameters),
      onEdit: () => setParameterSetIdForEdit(paramMap.id),
      onDelete: () => {
        deleteExplainParameterSets({
          variables: {
            workbookId: workbook.id,
            parameterSetId: paramMap.id,
          },
          onCompleted: () => {
            setErrorMessage("");
          },
          onError: (error) => {
            setErrorMessage(error.message);
          },
        });
      },
      autoCreatedFromQuery: paramMap.source === "auto_from_query",
    });
  });

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={workbookTitle}
      pageCategory="explains"
      pageName="workbooks"
    >
      <Panel
        title={<CreateSteps step="step2" workbook={workbook} />}
        secondaryTitle={!queryWithNoParams && editParameterSettings}
      >
        <PanelSection className="grid gap-4">
          <div className="relative">
            <QueryTextArea
              queryText={workbook.baselineQuery.queryTextWithAlias}
              className="max-h-[120px] overflow-y-scroll"
              showCopyToClipboard
            />
          </div>
          <PillButtonBar
            opts={[
              {
                value: "sample",
                label: "Extract parameters from query samples",
              },
              { value: "query", label: "Extract parameters from query" },
              { value: "manual", label: "Specify parameters manually" },
            ]}
            selected={addParamMethod}
            onChange={(value) => {
              setErrorMessage("");
              setErrorMessageForSamples("");
              setAddParamMethod(value);
            }}
            mode="stretch"
          />
          {addParamMethod === "sample" ? (
            <>
              <Callout thin>
                {queryWithSamples ? (
                  <>
                    We found the {querySamples.length} most interesting
                    parameter sets for you in your query samples. For the best
                    experience, select at least two distinct parameter sets.
                  </>
                ) : (
                  <>We did not find any parameter sets from query samples.</>
                )}
              </Callout>
              {queryWithSamples && (
                <>
                  <div className="w-full">
                    <input
                      className="bg-white rounded border border-gray-300 box-border h-8 leading-5 px-2 py-2 w-[400px]"
                      type="text"
                      placeholder="Search parameter sets..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                  <Grid
                    className="grid-cols-[1fr_140px_140px]"
                    data={filteredData}
                    defaultSortBy={"runtimeMs"}
                    noRowsText="No matching parameters"
                    columns={[
                      {
                        field: "jsonParameters",
                        header: "Parameter Set",
                        renderer: function ParametersCell({
                          rowData,
                          fieldData,
                        }) {
                          const jsonParams = jsonParametersToString(fieldData);
                          return (
                            <div className="flex gap-2">
                              <div>
                                <input
                                  type="checkbox"
                                  id="sample_params"
                                  checked={!!sampleIds.includes(rowData.id)}
                                  onChange={(evt) => {
                                    if (evt.target.checked) {
                                      if (sampleIds.length > 4) {
                                        setErrorMessageForSamples(
                                          "Only up to 5 samples can be selected",
                                        );
                                        return;
                                      }
                                      setSampleIds([...sampleIds, rowData.id]);
                                    } else {
                                      setSampleIds(
                                        sampleIds.filter(
                                          (id) => id !== rowData.id,
                                        ),
                                      );
                                    }
                                    setErrorMessageForSamples("");
                                  }}
                                />
                              </div>
                              <div title={jsonParams}>
                                <SQL
                                  className="!whitespace-nowrap"
                                  sql={jsonParams}
                                />
                                <div className="text-[12px] text-[#606060]">
                                  {formatTimestampShort(
                                    moment.unix(rowData.occurredAt),
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        },
                        disableSort: true,
                      },
                      {
                        field: "explain",
                        header: "Current Plan",
                        renderer: function PlanFingerprintCell({
                          fieldData,
                          rowData,
                        }) {
                          const fingerprint = fieldData.fingerprint;
                          return (
                            <Link
                              to={databaseQueryExplain(
                                databaseId,
                                baselineQuery.query.id,
                                rowData.explain.humanId,
                              )}
                            >
                              <Identicon identity={fingerprint} />
                              <span title={fingerprint}>
                                {fingerprint.substring(0, 7)}
                              </span>
                            </Link>
                          );
                        },
                        nullValue: "-",
                      },
                      {
                        field: "runtimeMs",
                        header: "Runtime",
                        renderer: MsCell,
                        defaultSortOrder: "desc",
                      },
                    ]}
                  />
                </>
              )}
              {errorMessageForSamples && (
                <div className="text-[#C22426]">
                  <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                  {errorMessageForSamples}
                </div>
              )}
            </>
          ) : (
            <CreateParameterSetPanel
              key={addParamMethod}
              workbook={workbook}
              addParamMethod={addParamMethod}
            />
          )}
        </PanelSection>
      </Panel>
      {parameterSetIdForEdit && (
        <EditParameterSetPanel
          onDismiss={handleEditDismiss}
          workbook={workbook}
          parameterSetId={parameterSetIdForEdit}
        />
      )}
      {showParameterSettingsEditPanel && (
        <EditParameterSettingsPanel
          onDismiss={() => setShowParameterSettingsEditPanel(false)}
          workbook={workbook}
        />
      )}
      <Panel title="Selected Parameter Sets">
        <PanelSection className="grid gap-4">
          {selectedParameters.length === 0 && (
            <div>
              {queryWithNoParams
                ? "This query does not require any parameter sets."
                : "You did not add any parameter sets yet, please choose at least one."}
            </div>
          )}
          {selectedParameters.length === 1 &&
            selectedParameters[0].autoCreatedFromQuery && (
              <Callout thin>
                We created a parameter set from the original query and selected
                it for you.
              </Callout>
            )}
          {selectedParameters.length > 0 && (
            <Grid
              className="grid-cols-[1fr_120px_70px]"
              data={selectedParameters}
              columns={[
                {
                  field: "paramInSql",
                  header: "Parameter Set",
                  renderer: function ParameterSetCell({ fieldData }) {
                    return <SQL sql={fieldData} />;
                  },
                },
                {
                  field: "fingerprint",
                  header: "Current Plan",
                  renderer: function CurrentPlanCell({ fieldData }) {
                    return (
                      <div>
                        <Identicon identity={fieldData} />
                        <span title={fieldData}>
                          {fieldData.substring(0, 7)}
                        </span>
                      </div>
                    );
                  },
                  nullValue: "-",
                },
                {
                  field: "onDelete",
                  header: "",
                  renderer: function OnDeleteCell({ fieldData, rowData }) {
                    return (
                      <div className="text-right">
                        {rowData.onEdit && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            title="Edit"
                            className="text-[#337AB7] mr-3 cursor-pointer"
                            onClick={rowData.onEdit}
                          />
                        )}
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          title="Delete"
                          className="text-[#CA1515] mr-1 cursor-pointer"
                          onClick={fieldData}
                        />
                      </div>
                    );
                  },
                },
              ]}
            />
          )}
          {errorMessage && (
            <div className="text-[#C22426]">
              <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
            </div>
          )}
          <div>
            <button
              className="btn btn-success"
              disabled={!queryWithNoParams && selectedParameters.length === 0}
              onClick={handleRunExplain}
            >
              Run EXPLAIN...
            </button>
          </div>
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

export const WorkbookCreationHeaderWithWorkbook = ({
  workbook,
}: {
  workbook: ExplainWorkbookType;
}) => {
  const [name, setName] = useState(workbook.name);
  const [description, setDescription] = useState(workbook.description || "");
  const { databaseWorkbooks } = useRoutes();
  const navigate = useNavigate();

  const [deleteExplainWorkbook] = useMutation<
    DeleteExplainWorkbook,
    DeleteExplainWorkbookVariables
  >(DELETE_WORKBOOK_MUTATION);

  const handleDeleteWorkbook = () => {
    deleteExplainWorkbook({
      variables: { workbookId: workbook.id },
      refetchQueries: [
        {
          query: WORKBOOK_LIST_QUERY,
          variables: {
            databaseId: workbook.databaseId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(databaseWorkbooks(workbook.databaseId));
      },
    });
  };

  return (
    <WorkbookCreationHeader
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      handleDeleteWorkbook={handleDeleteWorkbook}
      workbook={workbook}
    />
  );
};

export const WorkbookCreationHeader = ({
  name,
  description,
  setName,
  setDescription,
  handleDeleteWorkbook,
  workbook,
}: {
  name: string;
  description: string;
  setName: (name: string) => void;
  setDescription: (desc: string) => void;
  handleDeleteWorkbook: () => void;
  workbook?: ExplainWorkbookType;
}) => {
  const [showWorkbookNameEditPanel, setShowWorkbookNameEditPanel] =
    useState(false);

  function handleEditShow() {
    setShowWorkbookNameEditPanel(true);
  }
  function handleEditDismiss() {
    setShowWorkbookNameEditPanel(false);
  }

  return (
    <div className="flex flex-row justify-between items-center">
      <div>
        <h2 className="text-[22px] text-[#606060] m-0 py-[9px] font-medium overflow-visible leading-[26px]">
          {name}
        </h2>
        <div className="text-[14px] leading-5 font-normal flex">
          {description && (
            <span className="mr-2 max-w-[320px] truncate">{description}</span>
          )}
          <Button bare onClick={handleEditShow} className="text-[#337AB7]">
            {description ? "Edit" : "Edit name and description"}
          </Button>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          bare
          onClick={handleDeleteWorkbook}
          className="text-[14px] text-[#606060] font-normal"
        >
          Cancel workflow
        </Button>
      </div>
      {showWorkbookNameEditPanel && (
        <WorkbookNameEditPanel
          onDismiss={handleEditDismiss}
          name={name}
          description={description}
          setName={setName}
          setDescription={setDescription}
          workbook={workbook}
        />
      )}
    </div>
  );
};

export const CreateSteps = ({
  step,
  workbook,
}: {
  step: "step1" | "step2" | "step3";
  workbook?: ExplainWorkbookType;
}) => {
  const navigate = useNavigate();
  const { databaseWorkbooksNew, databaseWorkbookVariant } = useRoutes();

  const active = "text-[#29426D]";
  const inactive = "text-[#979797]";
  const step1Class = step === "step1" ? active : inactive;
  const step2Class = step === "step2" ? active : inactive;
  const step3Class = step === "step3" ? active : inactive;

  const [deleteExplainWorkbook] = useMutation<
    DeleteExplainWorkbook,
    DeleteExplainWorkbookVariables
  >(DELETE_WORKBOOK_MUTATION);

  const [deleteExplainResults] = useMutation<
    DeleteExplainResults,
    DeleteExplainResultsVariables
  >(DELETE_RESULTS_MUTATION);

  const handleBackToReviewQuery = () => {
    if (
      window.confirm(
        "Going back to the Review query step? All selected parameter sets will be cleared.",
      )
    ) {
      deleteExplainWorkbook({
        variables: { workbookId: workbook.id },
        onCompleted: () => {
          navigate(databaseWorkbooksNew(workbook.databaseId), {
            state: {
              queryText: workbook.baselineQuery.queryText,
              name: workbook.name,
              description: workbook.description,
            },
          });
        },
      });
    }
  };
  const handleBackToChooseParameters = () => {
    if (
      window.confirm(
        "Going back to the Choose parameters step? All uploaded or executed EXPLAIN results will be cleared.",
      )
    ) {
      deleteExplainResults({
        variables: {
          explainQueryId: workbook.baselineQuery.id,
          parameterSetsSelected: false,
        },
        refetchQueries: [
          {
            query: WORKBOOK_DETAIL_QUERY,
            variables: {
              workbookId: workbook.id,
              databaseId: workbook.databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(
            databaseWorkbookVariant(
              workbook.databaseId,
              workbook.id,
              workbook.baselineQuery.id,
            ),
          );
        },
      });
    }
  };

  return (
    <div className="flex justify-center align-middle text-[14px] gap-4 font-medium">
      <div
        className={classNames(step1Class, step !== "step1" && "cursor-pointer")}
        onClick={step !== "step1" ? handleBackToReviewQuery : undefined}
      >
        <FontAwesomeIcon icon={faCircle1} /> Review query
      </div>
      <div
        className={classNames(step2Class, step === "step3" && "cursor-pointer")}
        onClick={step === "step3" ? handleBackToChooseParameters : undefined}
      >
        <FontAwesomeIcon icon={faCircle2} /> Choose parameters
      </div>
      <div className={step3Class}>
        <FontAwesomeIcon icon={faCircle3} /> Run EXPLAIN
      </div>
    </div>
  );
};

export default ChooseParameters;
