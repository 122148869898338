import React, { useMemo } from "react";

import queryString from "query-string";

import classNames from "classnames";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import ExplainDetails from "components/ExplainDetails";
import ExplainSidebar from "components/ExplainSidebar";
import ExpandableSQL from "components/ExpandableSQL";
import WithNodeSelection from "components/Explain/WithNodeSelection";
import PillButtonBar from "components/PillButtonBar";

import moment from "moment-timezone";

import { formatTimestampShort } from "utils/format";

import ExplainComparison, { useComparisonPlans } from "./ExplainComparison";
import ExplainViewSource from "./ExplainViewSource";
import WithExplainDisplayOptions from "./WithExplainDisplayOptions";
import type { ExplainPlanType, QuerySampleType } from "components/Explain/util";

import styles from "./style.module.scss";
import WithExplainPlan from "components/WithExplainPlan";
import { AnnotatedPlan } from "types/explain";
import ExplainCompareSidebar from "components/ExplainCompareSidebar";
import PanelParamSection from "./PanelParamSection";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

import QUERY from "./Query.graphql";
import {
  ExplainComparison as ExplainComparisonType,
  ExplainComparisonVariables,
} from "./types/ExplainComparison";
import { useRoutes } from "utils/routes";

type Props = {
  explain: ExplainPlanType;
  databaseId: string;
  blockSize: number;
  hideComparison?: boolean;
};
type ViewType = "viz" | "text" | "json" | "comparison";

const ExplainPanel: React.FunctionComponent<Props> = ({
  explain,
  databaseId,
  blockSize,
  hideComparison,
}) => {
  const { databaseQueryExplainCompare } = useRoutes();
  const currView = useCurrentView();
  const viewLink = useViewLink();

  const plan = useMemo<AnnotatedPlan>(() => {
    return JSON.parse(explain.annotatedJson);
  }, [explain.annotatedJson]);

  const pillButtonOptions = [
    { value: viewLink("viz"), label: "Node Tree" },
    { value: viewLink("text"), label: "Text" },
    { value: viewLink("json"), label: "JSON" },
  ];
  if (!hideComparison) {
    pillButtonOptions.push({
      value: viewLink("comparison"),
      label: "Compare Plans",
    });
  }

  // Note that we are fetching data here rather than in (a wrapper to) the
  // ExplainComparison component because we also want to pass down the selected
  // plan information to the ExplainDetails panel.
  //
  // TODO: Rework the data fetching to only load the annotated JSON for the
  // plans being compared
  const skipCompareQuery = currView !== "comparison";
  const { error, loading, data } = useQuery<
    ExplainComparisonType,
    ExplainComparisonVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId: explain.query.id,
    },
    skip: skipCompareQuery,
  });

  const comparablePlans = useMemo(() => {
    return data?.getQueryExplains.map((e) => ({
      id: e.humanId,
      seenAt: e.seenAt,
      fingerprint: e.fingerprint,
      label: formatTimestampShort(moment.unix(e.seenAt)),
      runtime: e.querySample?.runtimeMs,
      ioMs: e.totalBlkReadTime,
      ioBytes: e.totalSharedBlksRead * blockSize,
      totCost: e.totalCost,
      plan: JSON.parse(e.annotatedJson),
    }));
  }, [data, blockSize]);

  const comparisonPlans = useComparisonPlans(comparablePlans);

  if (!skipCompareQuery && (loading || error)) {
    return <Loading error={!!error} />;
  }

  const [planA, planB] = comparisonPlans ?? [undefined, undefined];

  const currentPlan = planA ?? {
    id: explain.humanId,
    seenAt: explain.seenAt,
    fingerprint: explain.fingerprint,
    label: formatTimestampShort(moment.unix(explain.seenAt)),
    runtime: explain.querySample.runtimeMs,
    ioMs: explain.totalBlkReadTime,
    ioBytes: explain.totalSharedBlksRead * blockSize,
    totCost: explain.totalCost,
    plan,
  };

  function linkToComparison(planA: string, planB?: string) {
    return databaseQueryExplainCompare(
      databaseId,
      explain.query.id,
      planA,
      planB,
    );
  }

  return (
    <div className="px-[20px] flex-grow h-min overflow-auto">
      <div className="h-full flex">
        <WithExplainPlan plan={currentPlan} comparePlan={planB}>
          <WithNodeSelection>
            <WithExplainDisplayOptions>
              <div className="flex-grow relative overflow-y-scroll pl-1 -ml-1 pr-4">
                <div
                  className={classNames(
                    "py-4 mb-1 flex gap-5 sticky top-0 z-50 px-1 -mx-1",
                    styles.buttonBar,
                  )}
                >
                  <PillButtonBar
                    opts={pillButtonOptions}
                    selected={viewLink(currView)}
                  />
                </div>
                <QueryPanel querySample={explain.querySample} />
                {currView === "comparison" ? (
                  <ExplainComparison
                    comparablePlans={comparablePlans}
                    linkToComparison={linkToComparison}
                  />
                ) : currView === "json" || currView === "text" ? (
                  <div className="mt-8">
                    <ExplainViewSource
                      format={currView}
                      annotatedPlan={plan}
                      textPlan={explain.outputText}
                    />
                  </div>
                ) : currView === "viz" ? (
                  <ExplainDetails plan={plan} databaseId={databaseId} />
                ) : null}
              </div>
              {/* N.B.: pb is to clear the Get Help button in the lower right corner */}
              <div className="p-4 pb-[80px] border-l w-[480px] -mr-4 shrink-0 overflow-y-scroll">
                {currView === "comparison" ? (
                  <ExplainCompareSidebar
                    blockSize={blockSize}
                    databaseId={databaseId}
                  />
                ) : (
                  <ExplainSidebar
                    explain={explain}
                    blockSize={blockSize}
                    plan={plan}
                    databaseId={databaseId}
                    summaryOnly={currView === "json" || currView === "text"}
                  />
                )}
              </div>
            </WithExplainDisplayOptions>
          </WithNodeSelection>
        </WithExplainPlan>
      </div>
    </div>
  );
};

function useCurrentView(): ViewType {
  const location = useLocation();
  const search = queryString.parse(location.search);
  return (search.view ?? "viz") as ViewType;
}

function useViewLink(): (type: ViewType) => string {
  const location = useLocation();
  // preserve the URL state of time range selection
  const { view: _ignored, t } = queryString.parse(location.search);

  return function viewLink(type: ViewType) {
    const newSearch = type === "viz" ? { t } : { t, view: type };
    const result = queryString.stringify(newSearch);
    return result.length > 0 ? "?" + result : "";
  };
}

const QueryPanel = ({ querySample }: { querySample: QuerySampleType }) => {
  if (!querySample) {
    return null;
  }
  const parameters = querySample.parameters;
  return (
    <Panel title="SQL Statement">
      <PanelSection>
        <ExpandableSQL sql={querySample.queryText} />
      </PanelSection>
      <PanelParamSection parameters={parameters} />
    </Panel>
  );
};

export default ExplainPanel;
